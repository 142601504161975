import travelObject from '@/entities/travel/interface'
import { TFunction } from 'next-i18next'
import { buildTravelDatesLabel } from '@/lib/helpers/travel'

export const buildText = (travel: travelObject, t: TFunction) => {
  // tour guides
  let text = buildGuideText(travel, t)
  if (text) {
    text += `<span class="d-sm-none"><br /></span><span class="d-none d-sm-inline-block">&nbsp;|&nbsp;</span>`
  }

  // travel dates
  text += buildTravelDatesLabel(travel.pricing.travels, t)

  return text
}

const buildGuideText = (travel: travelObject, t: TFunction) => {
  let text = ''
  if (travel.travelGuides.length) {
    text = `${t('with')} `
  }
  travel.travelGuides.forEach((guide, index: number) => {
    if (index > 0) {
      text += ` ${t('and')} `
    }

    text += `<span class="text-nowrap">${guide.firstName} ${guide.lastName}</span>`
  })

  return text
}

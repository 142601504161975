import styles from './TravelResultItemTile.module.scss'
import Link from 'next/link'
import { Card } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import { formatPrice, getToday } from '@/lib/helpers/helpers'
import { TravelFilterTravel } from '@/features/travelFilter/types'
import mediaFileObject from '@/entities/mediaFile/interface'
import { buildFilterTravelDatesLabel } from '@/lib/helpers/travel'
import { TFunction, useTranslation } from 'next-i18next'
import TeaserFlag from '@/components/elements/TeaserFlag'

const buildLabel = (travel: TravelFilterTravel, t: TFunction) => {
  if (new Date(travel.startDate).getTime() < getToday().getTime()) {
    return t('travel.nowOnTour')
  }
  if (travel.label) {
    return travel.label
  }
  return null
}

interface Props {
  travel: TravelFilterTravel
}

const TravelResultItemTile = ({ travel }: Props) => {
  const { t } = useTranslation('common')

  const label = buildLabel(travel, t)

  return (
    <Link href={`/tour/${travel.urlSlug}`} passHref>
      <a className={styles.wrap}>
        <Card className={styles.card + ' an-up'}>
          <div className="img-ratio-16-9">
            <CloudinaryImage
              image={travel.mainImage as mediaFileObject}
              className={'card-img'}
              altDefault={travel.name}
              aspectRatio={1.777}
              width={524}
              responsive={false}
              placeholderClassName={'position-absolute'}
            />
          </div>
          {label && <TeaserFlag text={label} />}
          <Card.Body className={styles.body}>
            <Card.Title className={styles.title}>{travel.name}</Card.Title>
            <Card.Text as={'div'}>
              <div className={styles.date}>{buildFilterTravelDatesLabel(travel, t)}</div>
              <div className={styles.price}>{`${t('booking.priceFrom')} ${formatPrice(travel.minPrice, travel.currency)}`}</div>
            </Card.Text>
          </Card.Body>
        </Card>
      </a>
    </Link>
  )
}
export default TravelResultItemTile

import CustomButton from '@/components/elements/buttons/CustomButton'

interface Props {
  label: string
  href?: string
  targetBlank?: boolean
  className?: string
}

const CardButton = (props: Props) => {
  return <CustomButton {...props} />
}

export default CardButton

import styles from './TextWithImageBlock.module.scss'
import { Card, Col, Row } from 'react-bootstrap'
import CloudinaryImage from '@/components/elements/media/CloudinaryImage'
import cmsTextWithImageBlockObject from '@/entities/cmsTextWithImageBlock/interface'
import Link from 'next/link'
import TextWithImageSlider from '@/components/models/cms/blocks/TextWithImageBlock/TextWithImageSlider'
import CardButton from '@/components/elements/buttons/CardButton'

const TextWithImageBlock = ({ title = '', subtitle = '', text = '', imagePosition = 'left', gallery, link }: cmsTextWithImageBlockObject) => {
  const imageColOptions = imagePosition === 'left' ? { span: 6 } : { span: 6, order: 2 }

  let imageComponent = (
    <div className="box-ratio-16-9">
      <div className={`box-ratio-inner ${styles.imgWrap}`} />
    </div>
  )

  if (gallery.images.length > 1) {
    imageComponent = <TextWithImageSlider gallery={gallery} />
  } else if (gallery.images[0]) {
    // simple image
    const image = gallery.images[0]
    imageComponent = (
      <div className="box-ratio-16-9">
        <div className={`box-ratio-inner ${styles.imgWrap}`}>
          <CloudinaryImage image={image} width={704} aspectRatio={1.777} responsive={false} className={'card-img d-lg-none'} />
          <CloudinaryImage image={image} width={704} aspectRatio={1.1} responsive={false} className={'card-img d-none d-xl-block'} />
          <CloudinaryImage image={image} width={704} aspectRatio={0.8} responsive={false} className={'card-img d-none d-lg-block d-xl-none'} />
        </div>
      </div>
    )
  }

  let linkComponent = <></>

  if (link.label && link.url) {
    linkComponent = <CardButton label={link.label} href={link.url} targetBlank={link.targetBlank} className={styles.ribbon} />

    // don't link slider images
    if (gallery.images.length <= 1) {
      imageComponent = (
        <Link href={link.url} passHref>
          <a aria-label={link.label}>{imageComponent}</a>
        </Link>
      )
    }
  }

  return (
    <>
      <Card className={`${styles.root} ${imagePosition === 'left' ? styles.left : styles.right} ${link.url ? styles.withLink : ''}`}>
        <Row className="gx-0">
          <Col lg={imageColOptions}>{imageComponent}</Col>
          <Col lg={6}>
            <Card.Body className={styles.body}>
              <Card.Title dangerouslySetInnerHTML={{ __html: title }} />
              {subtitle && <div className={styles.subTitle} dangerouslySetInnerHTML={{ __html: subtitle }} />}
              <Card.Text as={'div'} className={styles.cardText} dangerouslySetInnerHTML={{ __html: text }} />
              {linkComponent}
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default TextWithImageBlock

import styles from '@/components/elements/TeaserFlag/TeaserFlag.module.scss'

interface Props {
  text: string
  className?: string
}
const TeaserFlag = ({ text, className = '' }: Props) => {
  return <div className={`${styles.root} ${className}`}>{text}</div>
}

export default TeaserFlag
